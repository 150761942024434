.am-tab-bar {
  height: 100%;
  overflow: hidden;
}

.am-tab-bar-bar {
  position: relative;
  box-sizing: border-box;
  height: 1.333333rem;
  border-top: 1PX solid #ddd;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  transition-duration: 0.2s;
  transition-property: height bottom;
  z-index: 100;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  align-items: center;
  bottom: 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-tab-bar-bar {
    border-top: none;
  }

  html:not([data-scale]) .am-tab-bar-bar::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-tab-bar-bar::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-tab-bar-bar-hidden-top {
  bottom: 1.333333rem;
  height: 0;
}

.am-tab-bar-bar-hidden-bottom {
  bottom: -1.333333rem;
  height: 0;
}

.am-tab-bar-bar .am-tab-bar-tab {
  -webkit-flex: 1 1;
  flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.am-tab-bar-bar .am-tab-bar-tab-image {
  width: 0.586667rem;
  height: 0.586667rem;
  vertical-align: middle;
}

.am-tab-bar-bar .am-tab-bar-tab-title {
  font-size: 0.266667rem;
  margin: 0.08rem 0 0 0;
  line-height: 1;
  text-align: center;
}

.am-tab-bar-bar .am-tab-bar-tab-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

.am-tab-bar-bar .am-tab-bar-tab-icon .tab-badge :last-child {
  margin-top: 0.106667rem;
  left: 0.586667rem;
}

.am-tab-bar-bar .am-tab-bar-tab-icon .tab-dot :last-child {
  margin-top: 0.106667rem;
  left: 0.586667rem;
}

.am-tab-bar-item {
  height: 100%;
}