.am-navbar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 1.2rem;
  background-color: #108ee9;
  color: #fff;
}

.am-navbar-left,
.am-navbar-title,
.am-navbar-right {
  -webkit-flex: 1 1;
  flex: 1 1;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.am-navbar-left {
  padding-left: 0.4rem;
  font-size: 0.426667rem;
}

.am-navbar-left-icon {
  margin-right: 0.133333rem;
  display: inherit;
}

.am-navbar-title {
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 0.48rem;
  white-space: nowrap;
}

.am-navbar-right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  font-size: 0.426667rem;
  margin-right: 0.4rem;
}

.am-navbar-light {
  background-color: #fff;
  color: #108ee9;
}

.am-navbar-light .am-navbar-title {
  color: #000;
}