.am-badge {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}

.am-badge-text {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: -0.16rem;
  height: 0.48rem;
  line-height: 0.48rem;
  min-width: 0.24rem;
  border-radius: 0.32rem;
  padding: 0 0.133333rem;
  text-align: center;
  font-size: 0.32rem;
  color: #fff;
  background-color: #ff5b05;
  white-space: nowrap;
  -webkit-transform: translateX(-45%);
  transform: translateX(-45%);
  -webkit-transform-origin: -10% center;
  transform-origin: -10% center;
  z-index: 10;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
}

.am-badge-text a {
  color: #fff;
}

.am-badge-text p {
  margin: 0;
  padding: 0;
}

.am-badge-hot .am-badge-text {
  background-color: #f96268;
}

.am-badge-dot {
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center;
  top: -0.106667rem;
  height: 0.213333rem;
  width: 0.213333rem;
  border-radius: 100%;
  background: #ff5b05;
  z-index: 10;
}

.am-badge-dot-large {
  height: 0.426667rem;
  width: 0.426667rem;
}

.am-badge-not-a-wrapper .am-badge-text,
.am-badge-not-a-wrapper .am-badge-dot {
  top: auto;
  display: block;
  position: relative;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.am-badge-corner {
  width: 2.133333rem;
  padding: 0.213333rem;
  position: absolute;
  right: -0.853333rem;
  top: 0.213333rem;
  background-color: #ff5b05;
  color: #fff;
  white-space: nowrap;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  text-align: center;
  font-size: 0.4rem;
}

.am-badge-corner-wrapper {
  overflow: hidden;
}